const getters = {
  appTitle: (state) => state.appTitle,
};

const state = {
  appTitle: "My Awesome App",
};

export default {
  state,
  getters,
};
